define("discourse/plugins/discourse-whos-online/discourse/api-initializers/intitialize-whos-online-indicators", ["exports", "@ember/service", "discourse/lib/api", "discourse-common/utils/decorators"], function (_exports, _service, _api, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const PLUGIN_ID = "whos-online";
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    var _dec, _obj, _dec2, _dec3, _obj2;
    const siteSettings = api.container.lookup("service:site-settings");
    const indicatorType = siteSettings.whos_online_avatar_indicator;
    if (indicatorType === "none") {
      return;
    }
    if (!(siteSettings.whos_online_display_public || api.getCurrentUser()?.trust_level >= siteSettings.whos_online_display_min_trust_level)) {
      return;
    }
    document.documentElement.classList.add(`whos-online-${indicatorType}`);
    api.modifyClass("component:user-card-contents", (_dec = (0, _decorators.default)("user", "whosOnline.users.[]"), (_obj = {
      pluginId: PLUGIN_ID,
      whosOnline: (0, _service.inject)(),
      classNameBindings: ["isOnline:user-online"],
      isOnline(user) {
        return user && this.whosOnline.isUserOnline(user.id);
      }
    }, (_applyDecoratedDescriptor(_obj, "isOnline", [_dec], Object.getOwnPropertyDescriptor(_obj, "isOnline"), _obj)), _obj)));
    api.modifyClass("route:user", (_dec2 = (0, _decorators.default)("whosOnlineUserId", "whosOnline.users.[]"), _dec3 = (0, _decorators.observes)("isOnline"), (_obj2 = {
      pluginId: PLUGIN_ID,
      whosOnline: (0, _service.inject)(),
      async afterModel(model) {
        const superVal = await this._super(...arguments);
        this.set("whosOnlineUserId", model.id);
        this.updateBodyClass();
        return superVal;
      },
      isOnline(userId) {
        return userId && this.whosOnline.isUserOnline(userId);
      },
      updateBodyClass() {
        if (this.isOnline) {
          document.body.classList.add("user-page-online");
        } else {
          document.body.classList.remove("user-page-online");
        }
      },
      deactivate() {
        this._super();
        document.body.classList.remove("user-page-online");
      }
    }, (_applyDecoratedDescriptor(_obj2, "isOnline", [_dec2], Object.getOwnPropertyDescriptor(_obj2, "isOnline"), _obj2), _applyDecoratedDescriptor(_obj2, "updateBodyClass", [_dec3], Object.getOwnPropertyDescriptor(_obj2, "updateBodyClass"), _obj2)), _obj2)));
    if (siteSettings.whos_online_avatar_indicator_topic_lists) {
      var _dec4, _obj3, _dec5, _obj4;
      api.modifyClass("component:topic-list-item", (_dec4 = (0, _decorators.default)("topic.lastPoster.id", "topic.lastPosterUser.id", "whosOnline.users.[]"), (_obj3 = {
        pluginId: PLUGIN_ID,
        whosOnline: (0, _service.inject)(),
        classNameBindings: ["isOnline:last-poster-online"],
        isOnline(lastPosterId, lastPosterUserId) {
          return this.whosOnline.isUserOnline(lastPosterId || lastPosterUserId);
        }
      }, (_applyDecoratedDescriptor(_obj3, "isOnline", [_dec4], Object.getOwnPropertyDescriptor(_obj3, "isOnline"), _obj3)), _obj3)));
      api.modifyClass("component:latest-topic-list-item", (_dec5 = (0, _decorators.default)("topic.lastPoster.id", "topic.lastPosterUser.id", "whosOnline.users.[]"), (_obj4 = {
        pluginId: PLUGIN_ID,
        whosOnline: (0, _service.inject)(),
        classNameBindings: ["isOnline:last-poster-online"],
        isOnline(lastPosterId, lastPosterUserId) {
          return this.whosOnline.isUserOnline(lastPosterId || lastPosterUserId);
        }
      }, (_applyDecoratedDescriptor(_obj4, "isOnline", [_dec5], Object.getOwnPropertyDescriptor(_obj4, "isOnline"), _obj4)), _obj4)));
    }
    api.modifyClass("component:scrolling-post-stream", {
      pluginId: PLUGIN_ID,
      didInsertElement() {
        this._super();
        this.appEvents.on("whosonline:changed", this, this._whosOnlineCallback);
      },
      willDestroyElement() {
        this.appEvents.off("whosonline:changed", this, this._whosOnlineCallback);
      },
      _whosOnlineCallback(changedUserIds) {
        changedUserIds.forEach(id => {
          let postIds = this.attrs.posts.value.filter(_ref => {
            let {
              user_id
            } = _ref;
            return user_id === id;
          }).map(post => post.id);
          postIds.forEach(postId => {
            this.dirtyKeys.keyDirty(`post-${postId}`);
            this.dirtyKeys.keyDirty(`post-${postId}-avatar-${id}`, {
              onRefresh: "updateOnline"
            });
          });
        });
        this.queueRerender();
      }
    });
    api.reopenWidget("post-avatar", {
      buildKey: attrs => `post-${attrs.id}-avatar-${attrs.user_id}`,
      isUserOnline(userId) {
        return this.register.lookup("service:whos-online").isUserOnline(userId);
      },
      defaultState(attrs) {
        return {
          online: this.isUserOnline(attrs.user_id)
        };
      },
      updateOnline() {
        this.state.online = this.isUserOnline(this.attrs.user_id);
      },
      buildClasses(attrs, state) {
        if (state.online) {
          return "user-online";
        }
        return [];
      }
    });
  });
});